/* eslint-disable import/prefer-default-export */
import React from "react";
import { CacheProvider } from "@emotion/react";
import getEmotionCache from "./getEmotionCache";
import { GatsbyBrowser } from "gatsby";

const cache = getEmotionCache();

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <CacheProvider value={cache}>{element}</CacheProvider>;
};

import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import PatrickHandTtf from "./fonts/Patrick_Hand/PatrickHand-Regular.ttf";
import OpenSansTtf from "./fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf";

export const primaryColor = "#b5e841";
export const secondaryColor = "#1c4b00";
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
                @font-face {
                    font-family: 'Patrick Hand';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('Patrick Hand'), url(${PatrickHandTtf}) format('truetype');
                }
                @font-face {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('Open Sans'), url(${OpenSansTtf}) format('truetype');
                }
            `,
    },
  },
  typography: {
    fontFamily: "'Open Sans', Roboto, sans-serif",
    h1: {
      fontWeight: 700,
      fontSize: "2rem",
      marginBottom: "2rem",
    },
    h2: {
      fontWeight: 700,
      fontSize: "1.5rem",
      marginBottom: "1.5rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.3rem",
      marginBottom: "1.3rem",
    },
  },
  shadows: [
    "none",
    "rgba(65,69,73,0.25) 0 1px 10px 0",
    "rgba(65,69,73,0.2) 0px 3px 1px -2px, rgba(65,69,73,0.14) 0px 2px 2px 2px, rgba(65,69,73,0.12) 0px 1px 5px 0px",
    "rgba(65,69,73,0.2) 0px 3px 3px -2px, rgba(65,69,73,0.14) 0px 3px 4px 0px, rgba(65,69,73,0.12) 0px 1px 8px 0px",
    "rgba(65,69,73,0.2) 0px 2px 4px -1px, rgba(65,69,73,0.14) 0px 4px 5px 0px, rgba(65,69,73,0.12) 0px 1px 10px 0px",
    "rgba(65,69,73,0.2) 0px 3px 5px -1px, rgba(65,69,73,0.14) 0px 5px 8px 0px, rgba(65,69,73,0.12) 0px 1px 14px 0px",
    "rgba(65,69,73,0.2) 0px 3px 5px -1px, rgba(65,69,73,0.14) 0px 6px 10px 0px, rgba(65,69,73,0.12) 0px 1px 18px 0px",
    "rgba(65,69,73,0.2) 0px 4px 5px -2px, rgba(65,69,73,0.14) 0px 7px 10px 2px, rgba(65,69,73,0.12) 0px 2px 16px 1px",
    "rgba(65,69,73,0.2) 0px 5px 5px -3px, rgba(65,69,73,0.14) 0px 8px 10px 2px, rgba(65,69,73,0.12) 0px 3px 14px 2px",
    "rgba(65,69,73,0.2) 0px 5px 6px -3px, rgba(65,69,73,0.14) 0px 9px 12px 2px, rgba(65,69,73,0.12) 0px 3px 16px 2px",
    "rgba(65,69,73,0.2) 0px 6px 6px -3px, rgba(65,69,73,0.14) 0px 10px 14px 2px, rgba(65,69,73,0.12) 0px 4px 18px 3px",
    "rgba(65,69,73,0.2) 0px 6px 7px -4px, rgba(65,69,73,0.14) 0px 11px 15px 2px, rgba(65,69,73,0.12) 0px 4px 20px 3px",
    "rgba(65,69,73,0.2) 0px 7px 8px -4px, rgba(65,69,73,0.14) 0px 12px 17px 3px, rgba(65,69,73,0.12) 0px 5px 22px 4px",
    "rgba(65,69,73,0.2) 0px 7px 8px -4px, rgba(65,69,73,0.14) 0px 13px 19px 3px, rgba(65,69,73,0.12) 0px 5px 24px 4px",
    "rgba(65,69,73,0.2) 0px 7px 9px -4px, rgba(65,69,73,0.14) 0px 14px 21px 3px, rgba(65,69,73,0.12) 0px 5px 26px 4px",
    "rgba(65,69,73,0.2) 0px 8px 9px -5px, rgba(65,69,73,0.14) 0px 15px 22px 3px, rgba(65,69,73,0.12) 0px 6px 28px 5px",
    "rgba(65,69,73,0.2) 0px 8px 10px -5px, rgba(65,69,73,0.14) 0px 16px 24px 3px, rgba(65,69,73,0.12) 0px 6px 30px 5px",
    "rgba(65,69,73,0.2) 0px 8px 11px -5px, rgba(65,69,73,0.14) 0px 17px 26px 3px, rgba(65,69,73,0.12) 0px 6px 32px 5px",
    "rgba(65,69,73,0.2) 0px 9px 11px -5px, rgba(65,69,73,0.14) 0px 18px 28px 3px, rgba(65,69,73,0.12) 0px 7px 34px 6px",
    "rgba(65,69,73,0.2) 0px 9px 12px -6px, rgba(65,69,73,0.14) 0px 19px 29px 3px, rgba(65,69,73,0.12) 0px 7px 36px 6px",
    "rgba(65,69,73,0.2) 0px 10px 13px -6px, rgba(65,69,73,0.14) 0px 20px 31px 4px",
    "rgba(65,69,73,0.2) 0px 11px 14px -7px, rgba(65,69,73,0.14) 0px 21px 33px 4px, rgba(65,69,73,0.12) 0px 8px 40px 7px",
    "rgba(65,69,73,0.2) 0px 11px 14px -7px, rgba(65,69,73,0.14) 0px 22px 35px 4px, rgba(65,69,73,0.12) 0px 8px 42px 7px",
    "rgba(65,69,73,0.2) 0px 11px 15px -7px, rgba(65,69,73,0.14) 0px 23px 36px 4px, rgba(65,69,73,0.12) 0px 9px 44px 8px",
    "rgba(65,69,73,0.2) 0px 11px 15px -7px, rgba(65,69,73,0.14) 0px 24px 38px 4px, rgba(65,69,73,0.12) 0px 9px 46px 8px",
  ],
  shape: {
    borderRadius: 4,
  },
  palette: {
    background: {
      default: "#fff",
    },
    info: {
      main: "#5A33AE",
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    error: {
      main: "#e53238",
    },
  },
});

export const CustomThemeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

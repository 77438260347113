/* eslint-disable import/prefer-default-export */
import * as React from "react";
import TopLayout from "./TopLayout";
import { GatsbyBrowser } from "gatsby";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
  pathname,
}) => {
  console.log({ pathname });
  return <TopLayout>{element}</TopLayout>;
};

import * as React from "react";
import { Helmet } from "react-helmet";
import { CustomThemeProvider } from "@kleinanzeigen-tool/shared/theme";

const TopLayout: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <CustomThemeProvider>{props.children}</CustomThemeProvider>
    </React.Fragment>
  );
};

export default TopLayout;
